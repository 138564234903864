import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/common.less'
import axios from 'axios'
import { buttonPermission } from "./utils/helper";
import {logDebug} from "./request/config.js"

// 导入时间插件momentjs
import moment from 'moment'
import CanvasPoster from 'vue-canvas-poster'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import '../public/static/UE/ueditor.config.js'
import '../public/static/UE/ueditor.all.js'
import '../public/static/UE/lang/zh-cn/zh-cn.js'
import '../public/static/UE/ueditor.parse.js'
import '../public/static/UE/themes/default/css/ueditor.css'
import '../public/static/UE/kityformula-plugin/addKityFormulaDialog.js'
import '../public/static/UE/kityformula-plugin/getKfContent.js'
import '../public/static/UE/kityformula-plugin/defaultFilterFix.js'
// 乐高编辑模式
Vue.prototype.$editor = true

Vue.use(CanvasPoster)


Vue.prototype.$moment = moment

// 定义全局时间格式过滤器
Vue.filter('dateFormat', function (daraStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(daraStr).format(pattern)
})


router.beforeEach((to, form, next) => {
  if (to.path == '/login') {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("AccountInfo");
    localStorage.removeItem("cid");
    localStorage.removeItem("token");
  }
  let user = JSON.parse(localStorage.getItem("userInfo"));
  if (!user && !['/login', '/tr/database'].includes(to.path)) {
    next({ path: '/login' })
  } else {
    next();
  }
  next();

})

Vue.prototype.$axios = axios

Vue.prototype.$buttonPermission = buttonPermission


Vue.config.productionTip = false


console.log = (function (oriLogFunc) {
  return function () {
    if (logDebug) {
      oriLogFunc.apply(this, arguments);
    }
  }
})(console.log);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
