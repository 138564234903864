<template>
  <div class="content">
    <div class="breadcrumb"
         v-if="active==0">
      <el-breadcrumb separator-class="el-icon-arrow-right"
                     class="breadcrumb-top">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>共享</el-breadcrumb-item>
        <el-breadcrumb-item>活动管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="bottom">
        <div class="left">活动列表</div>
        <div class="right"
             v-if="active==0">
          <el-button type="primary"
                     size="small"
                     @click="openDialogFormAdd('add')"
                     :disabled="!$buttonPermission('employeemanagement:create')">创建活动</el-button>
        </div>
      </div>
    </div>
    <div class="breadcrumb"
         v-if="active==1">
      <el-breadcrumb separator-class="el-icon-arrow-right"
                     class="breadcrumb-top">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>限时活动</el-breadcrumb-item>
        <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="bottom">
        <div class="left">{{title}}</div>
      </div>
    </div>
    <el-card class="operation-card"
             v-if="active==0">
      <el-form :inline="true"
               ref="searchForm"
               class="demo-form-inline">
        <el-form-item label="活动名称">
          <el-input v-model="query.name_keyword "
                    size="small"
                    placeholder="请输入活动名称搜索"
                    @input="inputSearch"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="活动状态"
                      prop="type">
          <el-select @change="inputSearch"
                     v-model="query.statuses "
                     placeholder="请选择活动状态"
                     style="width: 100%">
            <el-option v-for="(item, index) in typeList"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float:right">
          <el-button type="primary"
                     size="mini"
                     @click="handleSearch"
                     class="search">搜索</el-button>
          <el-button class="submit"
                     size="mini"
                     @click="handleFormReset('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="table-card"
             v-if="active==0"
             style="margin-top: 20px">
      <div class="card-header">
        <el-form size="small"
                 :inline="true">

        </el-form>
      </div>
      <el-table :data="result"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border
                @selection-change="handleSelectionChange">
        >

        <!-- <el-table-column prop="id"
                         label="ID"
                         width="50"></el-table-column> -->
        <el-table-column prop="id"
                         label="序列"
                         width="50">
        </el-table-column>
        <el-table-column prop="name"
                         label="活动名称">
          <template slot-scope="scope">
            <div class="left">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cover_picture_urls"
                         label="封面图"
                         min-width="100"
                         align="center"
                         class="tableImg">
          <template slot-scope="scope">
            <img class="avatar"
                 :src="scope.row.cover_picture_urls">
          </template>
        </el-table-column>
        <el-table-column prop="create_time"
                         width="300"
                         label="活动时间">
          <template slot-scope="scope">
            {{  $moment(scope.row.start_time).format("YYYY-MM-DD HH:mm") }}至 {{  $moment(scope.row.end_time).format("YYYY-MM-DD HH:mm") }}
          </template>

        </el-table-column>
        <el-table-column prop="create_time"
                         width="300"
                         label="注册时间">
          <template slot-scope="scope">
            <span v-if="scope.row.join_user_register_start_time !=null"> {{  $moment(scope.row.join_user_register_start_time).format("YYYY-MM-DD HH:mm") }}至
              {{  $moment(scope.row.join_user_register_end_time).format("YYYY-MM-DD HH:mm") }}</span>
            <span v-else>-</span>

          </template>

        </el-table-column>
        <el-table-column prop="target_finish_count"
                         label="任务类型"
                         width="80">
          <template slot-scope="scope">
            <div>发单任务</div>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         align="center"
                         show-overflow-tooltip
                         label="活动状态">
          <template slot-scope="scope">
            <el-tag type="danger"
                    v-if="scope.row.status == 0">已下线</el-tag>
            <el-tag type="success"
                    v-if="scope.row.status == 1">进行中</el-tag>
            <el-tag type="info"
                    v-if="scope.row.status == 2">未开始</el-tag>
          </template>

        </el-table-column>
        <el-table-column prop="sort"
                         label="排序值">
        </el-table-column>
        <el-table-column prop="manage_creator"
                         label="创建人">

        </el-table-column>
        <el-table-column prop="create_time"
                         label="创建时间"
                         width="160">
          <template slot-scope="scope"> {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}</template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="150">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToEdit(scope.row)"
                     :disabled="!$buttonPermission('employeemanagement:edit')">编辑</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link type="danger
              "
                     :underline="false"
                     @click="deleteItem(scope.row)"
                     :disabled="!$buttonPermission('employeemanagement:delete')">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <!-- <el-button size="small"
                   type="danger"
                   @click="chooseDelete">删除</el-button> -->
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 创建以及编辑活动 -->
    <el-card class="table-creat"
             v-if="active==1"
             style="margin-top: 20px;height:900px">
      <div class="form">
        <el-form :model="dataForm"
                 label-position="right"
                 ref="formRules"
                 :rules="rules"
                 label-width="100px"
                 size="small">
          <el-form-item label="活动名称"
                        prop="name">
            <el-input style="width: 360px"
                      maxlength="15"
                      show-word-limit
                      v-model.trim="dataForm.name"
                      placeholder="请输入活动名称">
            </el-input>
          </el-form-item>
          <el-form-item label="活动封面图"
                        prop="cover_picture_urls"
                        class="load">
            <el-upload class="avatar-uploader"
                       :action="imgUploadUrl"
                       :show-file-list="false"
                       :on-success="handleUploadSuccess"
                       :before-upload="handleBeforeUpload">
              <img v-if="dataForm.cover_picture_urls"
                   :src="dataForm.cover_picture_urls"
                   class="avatarImg">
              <i v-else
                 class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="活动时间"
                        class="time"
                        prop="time">
            <el-date-picker v-model="dataForm.time"
                            rules="[{ required: true, message: '请选择时间', trigger: 'blur' }]"
                            type="datetimerange"
                            format="yyyy-MM-dd HH:mm"
                            :default-time="['00:00:00', '23:59:59']"
                            align="right"
                            unlink-panels
                            @input="changeDate"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="timestamp">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="注册时间"
                        class="time"
                        prop="time">
            <el-date-picker v-model="dataForm.registerTime"
                            rules="[{ required: true, message: '请选择时间', trigger: 'blur' }]"
                            type="datetimerange"
                            format="yyyy-MM-dd HH:mm"
                            :default-time="['00:00:00', '23:59:59']"
                            align="right"
                            unlink-panels
                            @input="registerTimeDate"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="timestamp">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="选择任务"
                        prop="type">
            <el-select filterable
                       v-model="dataForm.type"
                       placeholder="请选择任务"
                       clearable
                       style="width: 150px">
              <el-option v-for="(item, index) in academyOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="dataForm.target_finish_count"
                      placeholder="请选择单数"
                      clearable
                      type="number"
                      style="width: 210px"><template slot="append">单</template></el-input>
          </el-form-item>
          <el-form-item label="选择奖品"
                        class="prize"
                        prop="prize">
            <el-select filterable
                       v-model="dataForm.prize"
                       placeholder="请选择奖品"
                       clearable
                       style="width: 150px">
              <el-option v-for="(item, index) in prizeOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="dataForm.target_profit_amount"
                      placeholder="请选择奖品金额"
                      clearable
                      type="number"
                      style="width: 210px"><template slot="append">元</template></el-input>
          </el-form-item>
          <el-form-item label="排序值"
                        class="sort"
                        prop="sort">
            <el-input v-model="dataForm.sort"
                      type="Number"></el-input>
          </el-form-item>
          <el-form-item label="上线状态"
                        style="marginLeft:-1px;">
            <el-switch v-model="dataForm.status"
                       :active-value="1"
                       :inactive-value="0">
            </el-switch>
          </el-form-item>
          <div class="xian"></div>
          <el-form-item>
            <el-button type="primary"
                       class="buttonColor"
                       @click="submit('formRules')">提交</el-button>
            <el-button @click="emitClose">取 消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="picture">
        <div class="preview">
          <div class="title">{{dataForm.name}}</div>
        </div>
        <!-- <div style="height:300px"> </div> -->
      </div>

    </el-card>
  </div>
</template>
<script>
import { activityList, userlist } from '@/request/api'
import { imgUploadUrl } from '@/request/http'
import { crmUrl } from '@/utils/helper'
let str = crmUrl()
export default {
  components: {},
  data() {
    return {
      id: '',
      title: '',
      prizeConut: '',
      result: [],
      imgUploadUrl: imgUploadUrl(),
      imgList: [],
      typeList: [
        {
          label: '全部',
          value: '0,1,2',
        },
        {
          label: '已下线',
          value: 0,
        },
        {
          label: '进行中',
          value: 1,
        },
        {
          label: '未开始',
          value: 2,
        },
      ],
      academyOptions: [
        {
          label: '发单任务',
          value: 1,
        },
      ],
      prizeOptions: [
        {
          label: '红包',
          value: 1,
        },
      ],
      prizesumOption: [
        {
          label: '100',
          value: 100,
        },
        {
          label: '200',
          value: 200,
        },
        {
          label: '300',
          value: 300,
        },
      ],
      targetOptions: [
        {
          label: '一单',
          value: 1,
        },
        {
          label: '二单',
          value: 2,
        },
        {
          label: '三单',
          value: 3,
        },
        {
          label: '四单',
          value: 4,
        },
        {
          label: '五单',
          value: 5,
        },
      ],
      sortOptions: [
        {
          value: '1',
        },
        {
          value: '2',
        },
        {
          value: '3',
        },
        {
          value: '4',
        },
      ],
      active: 0,
      create_time: '',
      dataForm: {
        target_finish_count: '',
        prize: '1',
        cover_picture_urls: '',
        time: [],
        registerTime: [],
      },
      createForm: {},
      tableData: [],
      query: { pageIndex: 1, pageSize: 10, status: '0,1,2' },
      pageTotal: 0,
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        cover_picture_urls: [
          { required: true, message: '请上传活动封面图', trigger: 'blur' },
        ],
        sort: [{ required: true, message: '请输入排序值', trigger: 'blur' }],
        time: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        type: [{ required: true, message: '请选择任务类型', trigger: 'blur' }],
        target_finish_count: [
          { required: true, message: '请选择任务单数', trigger: 'blur' },
        ],
        prize: [{ required: true, message: '请选择奖品类型', trigger: 'blur' }],
        target_profit_amount: [
          { required: true, message: '请选择金额数', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.fetchList()
  },
  mounted() {},
  methods: {
    handleUploadSuccess(res, file) {
      if (res.code == 0 && res.data) {
        this.dataForm.cover_picture_urls = res.data.url
        console.log('this.dataForm', this.dataForm)
      } else {
        this.$message.error(res.error)
      }
    },
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    changeDate() {
      this.$forceUpdate()
    },
    registerTimeDate() {
      this.$forceUpdate()
    },
    emitClose() {
      this.rest()
      this.active = 0
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/activity/list`,
        method: 'get', //可以省略 不写默认是get
        params,
      }).then((response) => {
        this.result = response.data.data.list || []
        this.pageTotal = response.data.data.count
        // 根据id去匹配名字
        userlist({ isPaging: 0 }).then((res) => {
          this.result.map((item) => {
            res.data.data.list.filter((i) => {
              if (item.manage_creator == i.id) {
                item.manage_creator = i.nickname
              }
            })
          })
        })
      })
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          console.log(
            'this.dataForm.registerTimeDate',
            this.dataForm.registerTimeDate
          )
          let query = {
            name: this.dataForm.name,
            start_time: this.dataForm.time[0],
            end_time: this.dataForm.time[1],
            join_user_register_start_time: this.dataForm.registerTime[0],
            join_user_register_end_time: this.dataForm.registerTime[1],
            type: 1,
            target_finish_count: this.dataForm.target_finish_count,
            target_profit_amount: this.dataForm.target_profit_amount,
            sort: this.dataForm.sort,
            status: this.dataForm.status,
            cover_picture_urls: this.dataForm.cover_picture_urls,
          }
          let str = crmUrl()
          if (this.title == '创建活动') {
            this.$axios({
              url: `${str}/api/v1/sharePlatform/activity`,
              method: 'post', //可以省略 不写默认是get
              data: query,
            }).then((res) => {
              if (res.data.code == 0) {
                this.active = 0
                this.rest()
                this.fetchList()
              }
            })
          } else if (this.title == '编辑活动') {
            let query = {
              name: this.dataForm.name,
              start_time: this.dataForm.time[0],
              end_time: this.dataForm.time[1],
              join_user_register_start_time: this.dataForm.registerTime[0],
              join_user_register_end_time: this.dataForm.registerTime[1],
              type: 1,
              target_finish_count: this.dataForm.target_finish_count,
              target_profit_amount: this.dataForm.target_profit_amount,
              sort: this.dataForm.sort,
              id: this.id,
              status: this.dataForm.status,
              cover_picture_urls: this.dataForm.cover_picture_urls,
            }
            this.$axios({
              url: `${str}/api/v1/sharePlatform/activity`,
              method: 'put', //可以省略 不写默认是get
              data: query,
            }).then((res) => {
              console.log('res', res)
              if (res.data.code == 0) {
                this.active = 0
                this.rest()
                this.fetchList()
              }
            })
          }
        }
      })
    },
    academyChange() {},
    // 搜索框搜索
    inputSearch(e) {
      this.$set(this.query, 'pageIndex', 1)
      this.fetchList(this.query)
    },
    handleSearch() {
      this.fetchList()
    },
    // 重置条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields()
      this.query = {
        name: '',
        status: '0,1,2',
      }
      this.fetchList(true)
    },
    handleSelectionChange() {},
    // 删除
    deleteItem(row) {
      let str = crmUrl()
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then((res) => {
          this.$axios({
            url: `${str}/api/v1/sharePlatform/activity`,
            method: 'delete', //可以省略 不写默认是get
            data: { id: row.id },
          }).then((res) => {
            if (res.data.code == 0) {
              this.fetchList()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },

    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    Number() {},
    // 编辑
    handleToEdit(row) {
      console.log('row', row)
      this.active = 1
      this.title = '编辑活动'
      this.dataForm = { ...row }
      this.dataForm.prize = 1
      this.dataForm.time = [row.start_time, row.end_time]
      if (row.join_user_register_start_time != null) {
        this.dataForm.registerTime = [
          row.join_user_register_start_time,
          row.join_user_register_end_time,
        ]
      }

      this.id = row.id
    },
    openDialogFormAdd() {
      this.active = 1
      this.title = '创建活动'
    },
    // 清除表单数据
    rest() {
      this.dataForm = {
        time: '',
        name: '',
        type: '',
        target_finish_count: '',
        target_profit_amount: '',
        sort: '',
        prize: '',
        cover_picture_urls: '',
      }
      this.$refs.formRules.resetFields()
    },
  },
}
</script>
<style>
.el-table .cell .avatar {
  height: 60px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  position: relative;
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 90px;
  line-height: 178px;
  text-align: center;
}

.avatar-uploader .el-upload img {
  width: 300px;
  height: 100px;
}
.avatar-uploader-icon {
  top: 29px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style lang="less" scoped>
.buttonColor {
  margin-left: -70px;
  background-color: #266fe8;
}

.content {
  background-color: #f5f5f5;
  .table-creat {
    width: 100%;
    height: 900px;
    .form {
      float: left;
      margin-left: -23px;
    }
    .picture {
      height: 640px;
      width: 374px;
      margin-right: 250px;
      margin-top: 20px;
      float: right;
      overflow-x: hidden;
      overflow-y: scroll;
      .preview {
        border: 1px solid transparent;
        position: relative;
        background: url('https://upload.kaoyanbox.net/3a52c3b205d88ae7bf2e0c6060423e38.jpg');
        width: 374px;
        height: 1068px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .title {
          text-align: center;
          position: absolute;
          top: 120px;
          left: 22px;
          width: 302px;
          height: 50px;
          font-family: 'HelloFont, HelloFont';
          font-weight: normal;
          font-size: 25px;
          color: #ffffff;
        }
        .event {
          text-align: center;
          position: absolute;
          top: 289px;
          left: 40%;
          .event-title {
            font-family: 'PingFangSC, PingFang SC';
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
          }
          .event-time {
            font-family: 'PingFangSC, PingFang SC';
            font-weight: 500;
            font-size: 22px;
            color: #fee5a9;
          }
        }
        .second {
          border: 1px solid transparent;
          margin: 300px auto;
          width: 343px;
          height: 239px;
          background: #ffffff;
          border-radius: 8px;
          .top {
            margin: 16px auto;
            display: flex;
            width: 240px;
            font-family: 'PingFangSC, PingFang SC';
            font-weight: 600;
            font-size: 18px;
            color: #6f0706;
            img {
              margin-top: 5px;
              margin-left: 8px;
              margin-right: 8px;
              width: 21px;
              height: 10px;
            }
          }
          .center {
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            width: 90%;
            .sanjiao {
              margin-top: 40px;
              width: 7px;
              height: 10px;
            }
            .box {
              width: 90px;
              height: 98px;
              background-color: #feddba;
              .chakan {
                margin-left: 25px;
                margin-top: 15px;
                width: 32px;
                height: 32px;
              }

              p {
                text-align: center;
                width: 100%;
                font-family: 'PingFangSC, PingFang SC';
                font-weight: 600;
                font-size: 12px;
                color: #802b02;
              }
            }
          }
          .button {
            position: relative;
            margin: 16px auto;
            width: 90%;
            height: 44px;
            background: linear-gradient(180deg, #f67954 0%, #ff3f49 100%);
            box-shadow: inset 0px 1px 4px 0px rgba(255, 255, 255, 0.7),
              inset 2px 1px 2px 0px rgba(255, 255, 255, 0.5);
            border-radius: 20px;
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            line-height: 44px;
            img {
              width: 47px;
              height: 47px;
              position: absolute;
              top: 9px;
            }
          }
        }
        .text {
          margin-top: 550px;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
  .breadcrumb {
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    width: 100%;
    height: 108px;
    position: relative;
    .breadcrumb-top {
      width: 40%;
      margin-top: -60px;
      margin-left: 24px;
    }
    .bottom {
      .left {
        position: absolute;
        left: 24px;
        font-weight: 600;
        font-size: 24px;
        color: #333333;
      }
      .right {
        position: absolute;
        right: 20px;
        .el-button {
          width: 76px;
          height: 35px;
          line-height: 25px;
          padding: 6px 10px;
          font-size: 14px;
          text-align: center;
          background-color: #266fe8;
        }
      }
    }
  }
  .operation-card {
    width: 100%;
    height: 84px;
    margin-top: 20px;
    .demo-form-inline {
      margin-top: 5px;
      height: 64px;
    }
    .search {
      background-color: #266fe8;
    }
  }

  .sort {
    ::v-deep .el-form-item__label {
      margin-left: -11px;
    }
    ::v-deep .el-input--small .el-input__inner {
      width: 150px;
    }
  }
  .xian {
    width: 100%;
    height: 20px;
    border-top: 1px solid #eeeeee;
  }
  .time {
    .el-date-editor--datetimerange.el-input__inner {
      width: 360px;
    }
  }
  .prize {
    ::v-deep .el-input-group {
      width: 150px;
    }
  }
}
</style>