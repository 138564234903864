<template>
  <div>
    <el-dialog
top="5vh"
      title="课程计划"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="课时主题："
          prop="course_theme"
        >
          <el-input
            v-model="dataForm.course_theme"
            placeholder="请输入课时主题"
            :maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="内容简介："
          prop="course_content"
        >
          <el-input
            type="textarea"
            v-model="dataForm.course_content"
            placeholder="请输入内容简介"
            :maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="计划时长："
          prop="plan_lesson_hour"
        >
          <el-input
            placeholder="请输入计划时长"
            v-model="dataForm.plan_lesson_hour"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            @blur="hoursBlur"
          >
            <span slot="append">小时</span>
          </el-input>
        </el-form-item>

        <el-form-item
          label="上课时间："
          prop="plan_start_time"
        >
          <div class="">
            <el-date-picker
              v-model="dataForm.plan_start_time"
              type="datetime"
              style="width:260px;"
              placeholder="请选择日期时间"
              clearable
              value-format="timestamp"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item
          label="所在班级："
          prop="grade"
        >
          <el-select
            v-model="dataForm.grade"
            placeholder="请选择"
            style="width:260px;"
            clearable
            @change="gradeChange"
            filterable
          >
            <el-option
              v-for="(item,index) in grades"
              :key="index"
              :label="item.name"
              :value="{ value: item.id, label: item.name,learn_manager:item.learn_manager}"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="授课老师："
          prop="teacher_id"
        >
          <div class="user-wrap">
            <div class="top">
              <el-link
                type="primary"
                :underline="false"
                :disabled="!dataForm.grade"
                @click="chooseDialog=true"
              >选择老师</el-link>
            </div>
            <div class="bottom">
              <el-tag
                :disable-transitions="false"
                v-if="teacherChoose"
              >
                {{teacherChoose.realname}}{{teacherChoose.teacher_average_lesson_hour_amount||0}}元/小时
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="课程阶段："
          prop="course_stage"
        >
          <el-select
            v-model="dataForm.course_stage"
            placeholder="请选择"
            style="width:260px;"
            clearable
          >
            <el-option
              label="基础"
              :value="0"
            ></el-option>
            <el-option
              label="强化"
              :value="1"
            ></el-option>
            <el-option
              label="冲刺"
              :value="2"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="备注："
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
    <MatchingTeacher
      :isVisible="chooseDialog"
      @close="chooseDialog=false"
      @submit="chooseTeacher"
      :grade_id="grade_id"
    />
  </div>
</template>

<script>



import {
  coursePlanCreate,
  gradelist,
} from "@/request/api";
import MatchingTeacher from "@/components/DialogComponents/ClassService/MatchingTeacher.vue";

import {
  getStorage
} from "@/utils/helper";
export default {
  components: {
    MatchingTeacher
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    plan_grade_id: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        grade: [{ required: true, message: "请选择班级", trigger: "blur" }],
        course_theme: [{ required: true, message: "请输入课时主题", trigger: "blur" }],
        course_content: [{ required: true, message: "请输入内容简介", trigger: "blur" }],
        course_stage: [{ required: true, message: "请选择课程阶段", trigger: "blur" }],
        course_target: [{ required: true, message: "请输入课程目标", trigger: "blur" }],
        references: [{ required: true, message: "请输入参考资料", trigger: "blur" }],
        plan_lesson_hour: [
          { required: true, message: "请输入辅导课时", trigger: "blur" },
          { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }
        ],
        plan_start_time: [{ required: true, message: "请选择上课时间", trigger: "blur" }],
        teacher_id: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.teacherChoose) {
              callback(new Error('请选择老师'))
            } else {
              callback()
            }
          },
        }],
      },
      companySelect: 1,
      tipsText: "",
      grades: [],
      teacherChoose: null,
      chooseDialog: false,
      grade_id: null,
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.gradeList()
      }
    },
  },
  methods: {
    gradeList() {
      gradelist({ isPaging: 0 }).then((response) => {
        let res = response.data
        this.grades = res.data.list
        if (this.plan_grade_id) {
          this.grade_id = this.plan_grade_id
          let obj = this.grades.find(v => {
            return v.id == this.plan_grade_id
          })
          this.$set(this.dataForm, 'grade', {
            label: obj.name,
            value: obj.id
          })
        }
      });
    },
    emitClose() {
      this.dataForm = {}
      this.$refs['formRules'].clearValidate();
      this.$emit("close");
    },


    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            teacher_id: this.teacherChoose.id,
            grade_id: this.dataForm.grade.value,
            course_theme: this.dataForm.course_theme,
            course_content: this.dataForm.course_content,
            course_stage: this.dataForm.course_stage,
            course_target: this.dataForm.course_target,
            references: this.dataForm.references,
            plan_lesson_hour: this.dataForm.plan_lesson_hour,
            plan_start_time: this.dataForm.plan_start_time,
            learn_manager: this.dataForm.grade.learn_manager ? this.dataForm.grade.learn_manager.id : getStorage('userInfo').id,
            remark: this.dataForm.remark,
          }
          coursePlanCreate(query).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.emitClose()
              this.$emit('submit')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    hoursBlur() {
      if (!/^(\d+|\d+\.\d{1,2})$/.test(this.dataForm.plan_lesson_hour)) {
        this.tipsText = "输入最多两位小数的数字"
      } else {
        if (this.companySelect == 2) {
          this.tipsText = `${this.dataForm.plan_lesson_hour}小时等于${(this.dataForm.plan_lesson_hour * 1.33).toFixed(2)}课时`
        } else if (this.companySelect == 1) {
          this.tipsText = `${this.dataForm.plan_lesson_hour}课时等于${(this.dataForm.plan_lesson_hour * 0.75).toFixed(2)}小时`
        }
      }
    },
    chooseTeacher({ data }) {
      this.teacherChoose = data
    },
    gradeChange(e) {
      this.grade_id = e.value
    }
  },
};
</script>

<style lang="less" scoped>
.tips {
  font-size: 14px;
  color: #909399;
  line-height: 16px;
}
.user-wrap {
  .top {
    display: flex;
    align-items: center;
  }
  .bottom {
  }
}
</style>