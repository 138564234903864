<template>
  <div>
    <el-dialog
      top="5vh"
      title="记录"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="700px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="服务类型："
          prop="core_type"
        >
          <el-select
            v-model="dataForm.core_type"
            placeholder="请选择"
            style="width: 275px;"
          >

            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="记录内容："
          prop="content_text"
        >
          <el-input
            v-model="dataForm.content_text"
            type="textarea"
            placeholder="请输入内容或直接粘贴图片"
            style="width: 100%;"
            @paste.native="handlePaste"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="特殊提醒："
          prop="ait_user_ids"
        >
          <el-select
            v-model="dataForm.ait_user_ids"
            placeholder="请选择"
            clearable
            multiple
            style="width: 100%x;"
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item
          label="隐藏状态："
          prop="user_hide_status"
        >
          <el-select
            v-model="dataForm.user_hide_status"
            placeholder="请选择"
            
            style="width: 100%x;"
          >
            <el-option
              v-for="item in hideStatuList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传附件：">
          <el-radio-group v-model="content_type">
            <el-radio
              :label="1"
              :disabled="!!fileList.length"
            >多图</el-radio>

            <el-radio
              :label="2"
              :disabled="!!imgList.length"
            >文件</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label=""
          v-if="content_type==1"
        >
          <el-upload
            :action="imgUploadUrl"
            list-type="picture-card"
            :file-list="imgList"
            :limit="3"
            :on-remove="imgListRemove"
            :on-success="imgListSucccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label=""
          v-if="content_type==2"
        >
          <div class="wrap">
            <el-upload
              drag
              :action="imgUploadUrl"
              :file-list="fileList"
              multiple
              :on-remove="fileListRemove"
              :on-success="fileListSucccess"
              :limit="1"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </div>
        </el-form-item>

      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    <script>
import {
  learnManageRecordEdit,
  teacherOne,
  taOne,
  teacherEdit,
  dictionaryroot,
  learnManageRecordOne
} from "@/request/api";
import {
  getStorage
} from "@/utils/helper";
import { imgUploadUrl } from "@/request/http";

export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {},
      rules: {
        core_type: [{ required: true, message: "请选择服务类型", trigger: "blur" }],
        content_text: [{ required: true, message: "请输入记录内容", trigger: "blur" }],
      },
       hideStatuList:[
        {
          label:'不隐藏',
          value:1
        },
        {
          label:'对机构隐藏',
          value:2
        }
      ],
      options: [
         {
          value:'学员对接',
          id:1
        },
         {
          value:'师资匹配',
          id:2
        },
         {
          value:'师资确认',
          id:3
        }
        ,
         {
          value:'计划制定',
          id:4
        },
         {
          value:'上课提醒',
          id:5
        },
         {
          value:'课时确认',
          id:6
        },
         {
          value: "修改申请",
          id: 7,
        },
        {
          value: "终止申请",
          id: 8,
        },
        {
          value: "删除申请",
          id: 9,
        },
           {
          value: "学生修改",
          id: 10,
        },
         {
          value: "学生终止",
          id:11,
        },
         {
          value: "学生删除",
          id: 12,
        },
        {
          value: "班级终止",
          id:13,
        },
         {
          value: "班级删除",
          id: 14,
        },
      ],
      uploadImg: '',
      imgUploadUrl: imgUploadUrl(),
      fileList: [],
      imgList: [],
      content_type: 1,
    };
  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
        // this.fetchDictionary()
      }
    },
  },
  methods: {
    fetchDetail() {
      learnManageRecordOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
        if(res.data.ait_user_ids){
          this.dataForm.ait_user_ids =  this.dataForm.ait_user_ids.split(',').map(item=>Number(item))
        }
        // console.log('this.dataForm:', this.dataForm)
        if (res.data.content_img) {
          if(this.isJSON(res.data.content_img)){
            this.imgList = JSON.parse(res.data.content_img)
            console.log('this.imgList:', this.imgList)
          }else{
             this.imgList = res.data.content_img.split(',').map(item=>{
              return {
                url : item
              }
             })
          }
        
          this.content_type = 1
        } else if (res.data.content_file) {
          this.fileList = JSON.parse(res.data.content_file)
          this.content_type = 2

        }
      })
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          console.log("error：" + str + "!!!" + e);
          return false;
        }
      }
      console.log("It is not a string!");
    },
    fetchDictionary() {
      dictionaryroot({ code: 'LearnManageServiceType' }).then((response) => {
        let res = response.data
        this.options = res.data.values
      })
    },
    // 文件上传成功处理
    handleSuccess(response, file, fileList) {
      this.fileList.push({
        url: response.data.url,
      })
    },
    handlePaste(value) {
      if (this.imgList.length >= 3) {
        this.$message.error('最多可以传三张')
        return
      }
      let file = value.clipboardData.items[0]
      if (file.type.includes('image')) {
        let imgFile = file.getAsFile()
        const formData = new FormData()
        formData.append('file', imgFile)
        const axiosInstance = this.$axios.create({ withCredentials: false })
        axiosInstance({
          method: 'post',
          url: this.imgUploadUrl, // 上传地址，视情况更换
          data: formData,
        })
          .then(res => {
            // 调用成功回调
            this.imgList.push({
              url: res.data.data.url,
            })
          })
          .catch(function (err) {
            console.log(err)
            // 上传失败
          })
      }
    },
    handleToClose() {
      this.$emit("close");
      this.dataForm = {}
      this.fileList = []
      this.imgList = []
    },
    submit(rules) {
      console.log(this.fileList);
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            id: this.dataForm.id,
            core_type: this.dataForm.core_type,
            content_text: this.dataForm.content_text,
            ait_user_ids: this.dataForm.ait_user_ids ? this.dataForm.ait_user_ids.join(',') : '',
            content_img: this.formateUpload('img'),
            content_file: this.formateUpload('file'),
            user_hide_status:this.dataForm.user_hide_status
          }
          learnManageRecordEdit(data).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    formateUpload(type) {
      if (type == 'img') {
        if (this.content_type == 1) {
          return this.imgList.length ? JSON.stringify(this.imgList) : ''
        } else {
          return ''
        }
      } else if (type == 'file') {
        if (this.content_type == 2) {
          return this.fileList.length ? JSON.stringify(this.fileList) : ''
        } else {
          return ''
        }
      }
    },
    fileListRemove(file, fileList) {
      this.fileList = fileList
    },
    fileListSucccess(response, file, fileList) {
      if (response.code == 400) {
        this.$message.error(response.error)
      } else {
        this.fileList.push({
          name: file.raw.name,
          url: response.data.url,
        })
      }


    },
    imgListRemove(file, fileList) {
      console.log(file, fileList);
      this.imgList = fileList
    },
    imgListSucccess(response, file, fileList) {
      if (response.code == 400) {
        this.$message.error(response.error)
      } else {
        this.imgList.push({
          url: response.data.url,
        })
      }

    },

  },
};
    </script>
    <style lang="less" scoped>
/deep/.el-input-group__append {
  background: #eeeeee !important;
}
.wrap {
  /deep/.el-upload {
    width: 100%;
  }
  /deep/.el-upload-dragger {
    width: 100%;
  }
}
</style>